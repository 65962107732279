<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <img src="@/assets/images/logo/logo.svg" class="apro_logo" alt="" />
            </b-link>
            <!-- /Brand logo-->

            <!-- Tool bar -->
            <b-link class="tool-bar">
                <dark-toggler class="d-block float-right" />
                <Locale class="d-block float-right" />
            </b-link>
            <!-- Tool bar -->

            <!-- Login-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <div v-if="withSocialite" class="alert alert-primary">
                        <div class="alert-body">
                            <div class="d-flex align-items-center">
                                <b-avatar :src="query.avatar" size="lg" class="mr-1" />
                                <div>
                                    <p>{{ query.name }}</p>
                                    <p>{{ $t('messages.binding_socialite_login') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        {{ $t('messages.welcome') }}
                    </b-card-title>
                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form class="auth-login-form mt-2" @submit.prevent>
                            <!-- email -->
                            <validation-input
                                id="login-email"
                                v-model="form.email"
                                :label="$t('fields.email')"
                                field="email"
                                placeholder="john@example.com"
                                rules="required|email"
                            />
                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">{{ $t('fields.password') }}</label>
                                    <b-link v-b-modal.modal-forgot-password>
                                        <small>{{ $t('fields.forgot_password') }}</small>
                                    </b-link>
                                </div>
                                <validation-provider v-slot="{ errors }" name="password" rules="required">
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid' : null"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="form.password"
                                            :state="errors.length > 0 ? false : null"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <!-- captcha -->
                            <captcha ref="captcha" />
                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                                    {{ $t('fields.remember_me') }}
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                v-ripple.500="'rgba(255, 255, 255, 0.15)'"
                                type="submit"
                                variant="primary"
                                class="px-5"
                                @click="validationForm"
                            >
                                {{ $t('fields.login') }}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <b-card-text class="text-center mt-2">
                        <span>{{ $t('messages.new_on_our_platform') }}</span>
                        <b-link v-b-modal.modal-register>
                            <span>&nbsp;{{ $t('messages.create_an_account') }}</span>
                        </b-link>
                    </b-card-text>

                    <!-- divider -->
                    <div class="divider my-2">
                        <div class="divider-text">{{ $t('fields.or') }}</div>
                    </div>

                    <!-- social buttons -->
                    <div class="auth-footer-btn d-flex justify-content-center">
                        <template v-for="socialite in socialites">
                            <b-button
                                :key="socialite.code"
                                v-ripple.500="'rgba(255, 255, 255, 0.15)'"
                                class="rounded-circle"
                                :variant="socialite.code"
                                @click="redirectToSocialite(socialite)"
                            >
                                <b-img :src="socialite.logo" height="25" width="25" />
                            </b-button>
                        </template>
                    </div>
                </b-col>
            </b-col>
            <!-- /Login-->

            <!-- Right Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <swiper
                        class="swiper-paginations"
                        :options="swiperOptions"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                        <swiper-slide v-for="(data, index) in swiperData" :key="index">
                            <b-img class="w-100" :src="data.img" fluid />
                        </swiper-slide>

                        <div slot="pagination" class="swiper-pagination" />
                    </swiper>
                </div>
            </b-col>
            <!-- /Right Text-->
        </b-row>
        <b-modal id="modal-register" hide-header hide-footer centered content-class="rounded-lg" body-class="p-2">
            <template #default="{ hide }">
                <b-button
                    v-ripple.500="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon rounded-circle float-right"
                    aria-label="Close"
                    @click="hide()"
                >
                    <feather-icon icon="XIcon" />
                </b-button>
                <register />
            </template >
        </b-modal>
        <b-modal id="modal-forgot-password" hide-header hide-footer centered content-class="rounded-lg" body-class="p-2">
            <template #default="{ hide }">
                <b-button
                    v-ripple.500="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon rounded-circle float-right"
                    aria-label="Close"
                    @click="hide()"
                >
                    <feather-icon icon="XIcon" />
                </b-button>
                <forgot-password />
            </template >
        </b-modal>
        <b-modal id="modal-verify-email" hide-header hide-footer centered content-class="rounded-lg" body-class="p-2">
            <template #default="{ hide }">
                <b-button
                    v-ripple.500="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon rounded-circle float-right"
                    aria-label="Close"
                    @click="hide()"
                >
                    <feather-icon icon="XIcon" />
                </b-button>
                <verify-email />
            </template >
        </b-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import Captcha from '@/components/Captcha'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import ValidationInput from '@/components/ValidationInput'
import auth from '@/api/auth'
import register from '@/views/general-pages/Register.vue'
import ForgotPassword from '@/views/general-pages/ForgotPassword.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VerifyEmail from '@/views/general-pages/VerifyEmail.vue'

export default {
    components: {
        VerifyEmail,
        ForgotPassword,
        Captcha,
        DarkToggler,
        Locale,
        ValidationInput,
        register,
        Swiper,
        SwiperSlide,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            form: {
                password: '',
                email: '',
            },
            status: '',
            query: {
                avatar: '',
                email: '',
                name: '',
                socialite: '',
                encrypt_credentials: '',
            },
            withSocialite: false,
            swiperData: [
                { img: require('@/assets/images/pages/login-v2.svg') },
                { img: require('@/assets/images/pages/login-v2-dark.svg') },
            ],
            swiperOptions: {
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
        }
    },
    computed: {
        ...mapState('global', ['socialites']),
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            const token = this.$route.query.token
            const socialite = this.$route.query.socialite
            const encrypt_credential = this.$route.query.encrypt_credential
            this.$store.commit('auth/INITIALIZE_STORE')

            if (socialite && encrypt_credential) {
                this.withSocialite = true

                this.query = this.$route.query

                Object.assign(this.form, {
                    socialite,
                    encrypt_credential,
                })
            }

            if (token) {
                try {
                    await this.$store.commit('auth/SET_TOKEN', token)
                    await this.$store.dispatch('auth/detail')
                    setTimeout(() => {
                        this.redirectTo()
                    }, 1000)
                } catch (e) {
                    //
                }
            }
        },
        validationForm() {
            this.$refs.loginValidation.validate().then((success) => {
                if (success) {
                    this.$refs.captcha.execute(this.handleSubmit)
                }
            })
        },
        async handleSubmit(captcha_response) {
            try {
                const params = { ...this.form, captcha_response }
                const response = await auth.login(params)
                await this.$store.commit('auth/SET_TOKEN', response.data.data.token)
                await this.$store.dispatch('auth/detail')
                await this.redirectTo()
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.data.message,
                        icon: 'EditIcon',
                        variant: 'success',
                    },
                })
            } catch (error) {
                //
            } finally {
                //
            }
        },
        async redirectToSocialite(socialite) {
            try {
                const params = { code: socialite.code }
                const response = await auth.socialite(params)
                console.log('response', response)
                window.location.href = response.data.data.redirect_url
            } catch (error) {
                //
            } finally {
                //
            }
        },
        redirectTo() {
            this.$router.push('/')
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style lang="scss" scoped>
::v-deep #modal-register,
::v-deep #modal-forgot-password,
::v-deep #modal-verify-email {
    //background-color: #01223770;
    backdrop-filter: blur(5px);
}

</style>
