<template>
    <div>
        <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t('messages.resend_email_title', { email: email }) }}
        </b-card-title>
        <b-card-text class="mb-2"> {{ $t('messages.resend_email_description') }} </b-card-text>
        <slot name="default" />
        <b-button type="button" variant="primary" :disabled="disabled" @click="send">
            {{ $t('fields.resend') }}
        </b-button>
        &nbsp;{{ seconds }}
    </div>
</template>

<script>
import auth from '@/api/auth'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
    name: 'ResetSendEmail',
    props: {
        email: {
            type: String,
            required: true,
        },
        scope: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            timer: null,
            seconds: null,
        }
    },
    computed: {
        disabled() {
            return this.seconds > 3
        },
    },
    destroyed() {
        this.clearCountdown()
    },
    methods: {
        async send() {
            const params = {
                email: this.email,
                scope: this.scope,
            }
            console.log(params)

            try {
                const response = await auth.resendVerification(params)
                await this.countdown()
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.data.message,
                        icon: 'EditIcon',
                        variant: 'success',
                    },
                })
            } catch (error) {
                //
            } finally {
                //
            }
        },
        countdown() {
            this.seconds = 30
            this.timer = setInterval(() => {
                this.seconds--

                if (this.seconds <= 0) {
                    this.clearCountdown()
                }
            }, 1000)
        },
        clearCountdown() {
            if (this.timer) {
                clearInterval(this.timer)
                this.timer = null
                this.seconds = null
            }
        },
    },
}
</script>
