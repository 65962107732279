<template>
    <div class="">
        <b-row class="auth-inner m-0">
            <!-- Resend email-->
            <b-col class="d-flex align-items-center auth-bg px-2 py-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <reset-send-email :scope="form.scope" :email="form.email" />
                </b-col>
            </b-col>
            <!-- /Resend email-->
        </b-row>
    </div>
</template>

<script>
import ResetSendEmail from '@/components/ResetSendEmail'
import VerificationScope from '@/enums/VerificationScope'

export default {
    name: 'VerifyEmail',
    components: { ResetSendEmail },
    data() {
        return {
            form: {
                email: '',
            },
        }
    },
    computed: {},
    created() {
        let email = this.$route.query.email
        let scope = this.$route.query.scope

        if (email && VerificationScope.getValues().includes(scope)) {
            this.form.email = email
            this.form.scope = scope
        } else {
            // this.$router.push('/login')
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
