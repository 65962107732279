<template>
    <div class="">
        <b-row class="auth-inner m-0">
            <!-- Forgot password-->
            <b-col class="d-flex align-items-center auth-bg px-2 py-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        {{ $t('messages.forgot_password') }}
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{ $t('messages.reset_password_description') }}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="forgotPasswordForm" @submit.prevent>
                        <b-form class="auth-forgot-password-form mt-2">
                            <!-- email -->
                            <validation-input
                                id="forgot-password-email"
                                v-model="form.email"
                                :label="$t('fields.email')"
                                field="email"
                                placeholder="john@example.com"
                                rules="required|email"
                            />
                            <!-- captcha -->
                            <captcha ref="captcha" />
                            <b-button
                                v-ripple.500="'rgba(255, 255, 255, 0.15)'"
                                type="submit"
                                variant="primary"
                                block
                                @click="validationForm"
                            >
                                {{ $t('messages.send_reset_link') }}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <!--                    <p class="text-center mt-2">-->
                    <!--                        <b-link :to="{ name: 'login' }">-->
                    <!--                            <feather-icon icon="ChevronLeftIcon" /> {{ $t('messages.back_to_login') }}-->
                    <!--                        </b-link>-->
                    <!--                    </p>-->
                </b-col>
            </b-col>
            <!-- /Forgot password-->
        </b-row>
    </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import store from '@/store/index'
import ValidationInput from '@/components/ValidationInput'
import Captcha from '@/components/Captcha'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import VerificationScope from '@/enums/VerificationScope'
import auth from '@/api/auth'

export default {
    components: {
        ValidationInput,
        Captcha,
    },
    data() {
        return {
            // step: 1,
            appName: $themeConfig.app.appName,
            form: {
                email: '',
            },
        }
    },
    computed: {
        imgUrl() {
            let image = require('@/assets/images/pages/forgot-password-v2.svg')

            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                image = require('@/assets/images/pages/forgot-password-v2-dark.svg')

                return image
            }

            return image
        },
    },
    methods: {
        validationForm() {
            this.$refs.forgotPasswordForm.validate().then((success) => {
                if (success) {
                    this.$refs.captcha.execute(this.handleSubmit)
                }
            })
        },
        async handleSubmit(captcha_response) {
            try {
                const params = { ...this.form, captcha_response }
                const response = await auth.forgotPassword(params)

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.data.message,
                        icon: 'EditIcon',
                        variant: 'success',
                    },
                })
                this.$bvModal.hide('modal-forgot-password')
                this.$router.push({
                    path: '/login',
                    query: {
                        email: this.form.email,
                        scope: VerificationScope.RESET_PASSWORD,
                    },
                })
                this.$bvModal.show('modal-verify-email')
            } catch (error) {
                //
            } finally {
                //
            }
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
